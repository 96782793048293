<template>
  <v-row justify="center">
    <v-dialog
      v-model="showFormField"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ $t(`booking.reservations.${isOwner ? 'clientData' : 'paxData'}`, locale) }}</span>
        </v-card-title>
        <v-card-text>
          <v-container v-if="!isOwner">
            <v-row>
              <v-col
                cols="12"
                sm="12"
              >
                <v-autocomplete
                  v-model="type"
                  :value="type"
                  :items="formFieldsTypes"
                  item-text="name"
                  item-value="id"
                  :label="$t('booking.reservations.formFieldsTypes.title', locale).concat(' (*)')"
                  outlined
                  hide-details
                />
              </v-col>
              <v-col
                cols="12"
                sm="12"
              >
                <v-text-field
                  v-model="paxKey"
                  :label="$t('booking.reservations.key', locale).concat(' (*)')"
                  outlined
                  hide-details
                  placeholder=""
                />
                <small>Utilizado para enlazar los campos internamente. </small>
                <br/>
                <small>Para rellenar los datos del primer asistente con los del comprador se ha de poner alguno de los siguientes valores <b>
                  [name, lastName, email, locale, room]
                  </b>
                </small>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                style="padding: 0px 23px;"
              >
                <translate
                  v-model="paxLabel"
                  :languages="languages"
                  :label="$t('booking.reservations.label', locale)"
                  type="text"
                />
              </v-col>
              <v-col
                cols="12"
                sm="12"
              >
                <v-checkbox
                  :label="$t('booking.reservations.required', locale)"
                  v-model="required"
                  hide-details
                />
              </v-col>
            </v-row>
          </v-container>
          <v-container v-else-if="isOwner">
            <v-row>
              <v-col
                cols="12"
                sm="12"
              >
                <v-autocomplete
                  v-model="ownerKey"
                  :value="ownerKey"
                  :items="ownerFormFields"
                  item-text="name"
                  item-value="key"
                  :label="$t('booking.reservations.field', locale).concat(' (*)')"
                  outlined
                  hide-details
                />
              </v-col>
              <v-col
                cols="12"
                sm="12"
              >
                <v-checkbox
                  :label="$t('booking.reservations.required', locale)"
                  v-model="required"
                  hide-details
                />
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="default"
            text
            @click="() => { onClose() }"
          >
            {{ $t('common.close', locale) }}
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="handleSave"
          >
            {{ $t('common.save', locale) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import api from '@/services/api'
import utils from '@/services/utils'
import Translate from '@/components/Translate'
export default {
  components: {
    Translate,
  },
  props: {
    editedFormField: {
      type: Object,
      required: null,
    },
    locale: {
      type: String,
      default: null,
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    ownerFieldsUsed: {
      type: Array,
      default: null,
    },
    isOwner: {
      type: Boolean,
      default: false,
    },
    onClose: {
      type: Function,
      default: null,
    },
    isAssisted: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showFormField: false,
    loading: false,
    ownerFormFields: [],
    formFieldsTypes: [], // used for pax fields
    required: true,
    type: null,
    paxKey: null,
    paxLabel: { "es": '' },
    ownerKey: null,
  }),
  computed: {
    languages () {
      return this.$store.state.app.bookingLanguages
    },
    isNew () {
      return this.editedFormField.id === 'new'
    },
  },
  watch: {
    dialog () {
      this.showFormField = this.dialog
      if (this.dialog) this.handleGetData()
    },
    editedFormField () {
      this.handleGetData()
    },
  },
  mounted () {
    this.handleGetData()
    this.showFormField = this.dialog
  },
  methods: {
    prepareFormData (v) {
      if (!v) return
      if (this.isOwner) {
        if (v.key !== null) this.ownerKey = v.key
        if (v.required !== null) this.required = v.required
      } else {
        if (v.type !== null) this.type = v.type
        if (v.key !== null) this.paxKey = v.key
        if (v.label !== null) this.paxLabel = v.label
      }
    },
    handleGetData () {
      this.loading = true
      if (this.isOwner) {
        const ownerFieldsUsed = this.ownerFieldsUsed.map(item => item.key)
        api.getAllWithoutLimit('booking', 'v1/internal/constants/owner-form-fields')
          .then (response => {
            this.ownerFormFields = response.ownerFormFields.filter(x => ownerFieldsUsed.indexOf(x.key) < 0 || x.key === this.editedFormField.key)
              .map(item => {
                item.name =  utils.getTranslation(item.label, this.locale)
                return item
            })
            this.prepareFormData(this.editedFormField)
          })
      } else {
        api.getAllWithoutLimit('booking', 'v1/internal/constants/form-fields-types')
          .then (response => {
            this.formFieldsTypes = response.formFieldsTypes
              .map(item => {
                return {
                  id: item,
                  name: this.$t(`booking.reservations.formFieldsTypes.${item}`, this.locale)
                }
            })
            this.prepareFormData(this.editedFormField)
          })
      }
    },
    prepareBody () {
      const body = JSON.parse(JSON.stringify(this.editedFormField))
      body.isOwner = this.isOwner 
      body.required = this.required
      if (this.isOwner) {
        const aux = this.ownerFormFields.filter(x => x.key === this.ownerKey).shift()
        if (!aux) return
        body.label = aux.label
        body.key = aux.key
        body.type = aux.type
        body.maxLength = aux.maxLength
        return body
      } else {
        body.label = this.paxLabel
        body.key = this.paxKey
        body.type = this.type
        return body
      }
    },
    handleSave () {
      if (this.isAssisted) {
        this.onClose({
          id: this.editedFormField.id,
          required: this.required,
          label: this.paxLabel,
          key: this.paxKey,
          type: this.type,
        })
        return 
      }
      const body = this.prepareBody()
      if (!body) return
      api.updateItem('booking', `v1/private/activities/`, this.editedFormField.activityID.concat('/form-fields'), body)
        .then (response=> {
          console.log(response)
          this.onClose()
        })
    },
  },
}
</script>

